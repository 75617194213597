import React, { useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { app_url } from "../../config";
import { logout } from "../../store/Authaction";
import ForgetPassword from "./ForgetPassword";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logout());
    const isAuthenticated = localStorage.getItem("customerEtijwaal");
    if (isAuthenticated) {
      localStorage.removeItem("customerEtijwaal");
    }
  }, []);

  const loginuser = async (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      toast.error("Fill in the blanks");
    } else {
      setisLoading(true);
      try {
        // Replace with your API endpoint and credentials
        const response = await fetch(`${app_url}/api/customer/loginCustomer`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email, password: password }),
        });
        // const response = await axios.post(`${app_url}/api/portalUser/authUser`, { email: email, password: password })
        if (!response.ok) {
          toast.error("Invalid Credentials");
          setisLoading(false);

          return;
        }

        const user = await response.json();
        localStorage.setItem("customerEtijwaal", JSON.stringify(user));
        // navigate("/dashboard");
        navigate("/orders");

        toast.success(user.message);
        setisLoading(false);
        dispatch({ type: "LOGIN", payload: user });
      } catch (error) {
        toast.error(error.message);
        console.error("Error:", error);
        setisLoading(false);
      }
    }
  };

  return (
    <>
      <section className="login_screen">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <section className="form_navbar position-relatve ps-5">
                <img src="/assets/images/travel.png" className="bg-img" />
              </section>
            </div>
            <div className="col-md-6 p-md-5 p-3">
              <div className="w-50 mx-auto text-center">
                <img
                  className="login_logo mb-2"
                  src="/assets/logo/e-tijwaal.png"
                />
                <p className="text-danger fw-bold">
                  Please Sign in to continue
                </p>
              </div>

              <form onSubmit={loginuser} className="mt-5">
                <label className="fs-4">Email Address</label>
                <div className="input">
                  <input
                    className="login_input border bg-transparent"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter Your Purchasing Email"
                  />
                  {/* <span className='missing_field'>{isFieldMissing && !email ? 'Email is missing ' : ''}</span> */}
                </div>

                <label className="fs-4">Password</label>

                <div className="input">
                  <input
                    className="login_input border bg-transparent"
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="**********"
                  />

                  {!showPassword ? (
                    <AiFillEye
                      onClick={() => setshowPassword(true)}
                      className="eye_icon"
                    />
                  ) : (
                    <AiFillEyeInvisible
                      className="eye_icon"
                      onClick={() => setshowPassword(false)}
                    />
                  )}
                </div>
                <Link
                  data-bs-toggle="modal"
                  data-bs-target="#ForgetPasswordModal"
                  className="forget_link "
                >
                  Forget password?
                </Link>

                <button
                  class="login_button mt-4"
                  disabled={isLoading}
                  type="submit"
                >
                  <span className="text-white" role="status">
                    Login{" "}
                  </span>
                  {isLoading ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    ""
                  )}
                </button>
                <h6 className="registration">
                  New Customer?{" "}
                  <span
                    data-bs-toggle="modal"
                    data-bs-target="#ForgetPasswordModal"
                    className="pointer not_registered"
                  >
                    Confirm an Account{" "}
                  </span>
                </h6>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ForgetPassword />
    </>
  );
};

export default Login;
