import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { app_url } from "../../config";
import Pagination from "../../components/tables/Pagination";
import Orders_Table from "../../components/tables/Orders_Table";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";

export default function Topups() {
  const userdata = localStorage.getItem("customerEtijwaal");
  const token = JSON.parse(userdata).token;
  const userid = JSON.parse(userdata).customer.id;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tabledata, settabledata] = useState([]);
  const [search, setsearch] = useState("");
  const [dateFrom, setdateFrom] = useState("");
  const [dateTo, setdateTo] = useState("");
  const [ByYear, setByYear] = useState("");
  const [ByMonth, setByMonth] = useState("");
  const [dataOnPage, setdataOnPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const [packageId, setPackageId] = useState(null);
  const [systemOrderId, setSystemOrderId] = useState(null);
  const [topUpOrder, setTopupOrder] = useState(false);
  const [id, setId] = useState("");
  const itemsPerPage = dataOnPage;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const a = parseInt(itemsPerPage);
  const b = parseInt(indexOfFirstItem);

  const currentYears = new Date().getFullYear();
  const lastTenYears = Array.from({ length: 10 }, (v, i) => currentYears - i);

  {
    /* ================= Api =================== */
  }
  const modalClosed = () => setTopupOrder(false);

  useEffect(() => {
    setLoading(true);

    axios
      .get(
        `${app_url}/api/line/getAllEsimsTopUpsForCportal?numPerPage=${dataOnPage}&page=${
          currentPage - 1
        }&from=${dateFrom}&to=${dateTo}&customerId=${userid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 401) {
          navigate("/");
        }
        console.log(res);
        settabledata(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [search, dateFrom, dateTo, dataOnPage, currentPage]);

  {
    /* ================= Clear Pegination =================== */
  }

  const ClearDate = () => {
    setdateFrom("");
    setdateTo("");
    setsearch("");
    setByMonth("");
    setByYear("");
  };

  {
    /* ================= Handl Pagination Page =================== */
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  {
    /* ================= Sort By Year =================== */
  }
  const byyearFunc = (e) => {
    setByYear(e.target.value);
    if (ByMonth) {
      setdateFrom(
        new Date(e.target.value, ByMonth - 1, 2).toISOString().slice(0, 10)
      );
      setdateTo(
        new Date(e.target.value, ByMonth, 1).toISOString().slice(0, 10)
      );
      console.log(ByMonth);
    } else {
      setdateFrom(new Date(e.target.value, 0, 2).toISOString().slice(0, 10));
      setdateTo(new Date(e.target.value, 12, 1).toISOString().slice(0, 10));
      console.log(dateFrom, dateTo);
    }
  };

  {
    /* ================= Sort By Month =================== */
  }
  const bymonthFunc = (e) => {
    setByMonth(e.target.value);
    if (e.target.value) {
      if (ByYear) {
        setdateFrom(
          new Date(ByYear, e.target.value - 1, 2).toISOString().slice(0, 10)
        );
        setdateTo(
          new Date(ByYear, e.target.value, 1).toISOString().slice(0, 10)
        );
      } else {
        setdateFrom(
          new Date(new Date().getFullYear(), e.target.value - 1, 2)
            .toISOString()
            .slice(0, 10)
        );
        setdateTo(
          new Date(new Date().getFullYear(), e.target.value, 1)
            .toISOString()
            .slice(0, 10)
        );
      }
    }
  };

  return (
    <section className="wrapper">
      <p className="heading">Topups</p>
      <div className="table-card card ">
        <div className="card-body">
          {/* table header */}

          <div className="d-xl-flex justify-content-xl-between table-header-item  mb-2">
            {/* <div className="d-flex align-items-center justify-content-start">
              <span className="entries">Show</span>
              <select onChange={(e) => setdataOnPage(e.target.value)} className="ms-3 form-select ">
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
              </select>
              <span className="ms-3 entries">Entries</span>
            </div> */}

            <div className="d-flex align-items-center justify-content-center text-center my-3">
              <div className="order-date">
                <span className="pe-2">From:</span>
                <input
                  onChange={(e) => setdateFrom(e.target.value)}
                  value={dateFrom}
                  className="form-control"
                  type="date"
                />
              </div>
              <div className="order-date">
                <span className="pe-2">To:</span>
                <input
                  onChange={(e) => setdateTo(e.target.value)}
                  value={dateTo}
                  className="form-control"
                  type="date"
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-xl-start text-center my-3 w-100">
              <div className="order-date">
                <span className="pe-2 w-100">Month:</span>
                <select
                  name=""
                  value={ByMonth}
                  onChange={bymonthFunc}
                  className="form-select w-auto"
                  id=""
                >
                  <option value="" hidden>
                    Select Month
                  </option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
              <div className="order-date">
                <span className="pe-2 w-100">Year:</span>
                <select
                  onChange={byyearFunc}
                  value={ByYear}
                  name=""
                  className="form-select w-auto"
                  id=""
                >
                  <option value="" hidden>
                    Select Year
                  </option>
                  <option value="2000">2000</option>
                  <option value="2001">2001</option>
                  <option value="2002">2002</option>
                  <option value="2003">2003</option>
                  <option value="2004">2004</option>
                  <option value="2005">2005</option>
                  <option value="2006">2006</option>
                  <option value="2007">2007</option>
                  <option value="2008">2008</option>
                  <option value="2009">2009</option>
                  <option value="2010">2010</option>
                  <option value="2011">2011</option>
                  <option value="2012">2012</option>
                  <option value="2013">2013</option>
                  <option value="2014">2014</option>
                  <option value="2015">2015</option>
                  <option value="2016">2016</option>
                  <option value="2017">2017</option>
                  <option value="2018">2018</option>
                  <option value="2019">2019</option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                </select>
              </div>
            </div>
            <div className="d-flex w-100 justify-content-xl-end my-3 ">
              {/* <div className="text-end me-3">
                <input className="form-control" value={search} onChange={(e) => setsearch(e.target.value)} placeholder="Search" type="search" />
              </div> */}
              <div className="mt-0 w-auto  text-center">
                <button onClick={ClearDate} className="btn primary-btn">
                  Clear
                </button>
              </div>
            </div>
          </div>

          {/* table header end*/}

          {/* table body */}

          {/* <Orders_Table tabledata={tabledata} loading={loading} indexOfFirstItem={indexOfFirstItem} /> */}

          <div className="row order_card_parent mx-0">
            {loading ? (
              <>
                <p className="table-parant-loader">
                  <Loader />
                </p>
              </>
            ) : tabledata?.data?.result?.length === 0 ? (
              <>
                <p>
                  <p className="text-center heading-m mt-3"> No Top-ups!</p>
                </p>{" "}
              </>
            ) : (
              <>
                {" "}
                {tabledata?.data?.result?.map((item, i) => (
                  <>
                    <div className="col-lg-6 col-xl-4 px-sm-2 px-0 mt-5">
                      <div className="pointer text-decoration-none text-black h-100">
                        <div
                          key={item.packageId}
                          className={`order_card shadow-lg mb-4 custom-styles d-inline-block h-100 w-100 position-relative`}
                        >
                          {/* <img src="assets/images/displayCardImage.png" className="position-absolute top-0"/> */}
                          <Link>
                            <div>
                              {/* <img src={app_url + item.cardImage} alt="" /> */}
                              <div className="text-start">
                                <div className="d-flex align-items-top justify-content-between">
                                  <div className="text-start pe-2">
                                    <h1 className="fw-bold">
                                      {item.packageName}
                                    </h1>
                                  </div>
                                  <img
                                    src="/assets/images/topups.png"
                                    className="topups"
                                  />
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                  <p className="status fw-lighter m-0">
                                    {item.status}
                                  </p>
                                  {item.isTopUp === 1 ? (
                                    <label className="status fw-lighter  m-0 p-2  border">
                                      {" "}
                                      Top Up{" "}
                                    </label>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <hr />
                                <div className="d-flex align-items-center justify-content-between">
                                  <h5 className="issued-to">Data</h5>
                                  <p className="status fw-lighter">
                                    {item.remainingUsage === null
                                      ? 0
                                      : item.data}{" "}
                                  </p>
                                </div>
                                <hr />
                                <div className="d-flex align-items-center justify-content-between">
                                  <h5 className="issued-to">Validty</h5>
                                  <p className="status fw-lighter">
                                    {item.validity === 1
                                      ? `${item.validity} day`
                                      : `${item.validity} days`}
                                  </p>
                                </div>
                                <hr />
                                <div className="d-flex align-items-center justify-content-between">
                                  <h5 className="issued-to">Purchase Date</h5>
                                  <p className="status fw-lighter">
                                    {item.expiresAt === null
                                      ? "-"
                                      : `${new Date(
                                          item.createdAt
                                        ).toLocaleString()}`}
                                  </p>
                                </div>
                                <hr />
                              </div>
                            </div>
                          </Link>
                          <div>
                            <div className="d-flex justify-content-between align-items-center gap-1 mt-5">
                              <div className="d-flex flex-1 gap-2">
                                <small className="fw-light fs-4 align-self-start text-danger">
                                  {" "}
                                  {item.currency === "usd" ? "$" : ""}
                                </small>
                                <p className="fs-2 fw-bold">{item.price}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}
          </div>

          {/* <div className="row order_card_parent mx-0">

            {
              loading ?
                <>
                  <p className="table-parant-loader">
                    <Loader />
                  </p>
                </>
                : tabledata?.data?.result?.length === 0 ? <><p><p className="text-center heading-m mt-3"> No Result Found!</p></p> </> :
                  <> {tabledata?.data?.result?.map((item, i) => (
                    <>
                      <div className="col-lg-6 px-sm-2 px-0 mt-3">
                        <Link className="pointer text-decoration-none text-black" to={`/orders/${item.systemOrderId}`}>
                          <div className="order_card">
                            <div>
                              <div className="d-flex">
                                <img src={app_url + item.cardImage} alt="" />
                                <div>
                                  <p>{item.packageName}</p>
                                  <p>{item.title}</p>
                                  <p>{item.status}</p>
                                  <p className="order_card_date">{item.createdAt.slice(0, 10)}</p>
                                </div>
                              </div>
                            </div>
                            <div>
                              <p className="fs-4"><small className=""> {item.currency === 'usd' ? 'US$' : ''}</small> {item.price}
                                <i class="bi bi-chevron-right"></i></p>
                            </div>
                          </div>
                        </Link>
                      </div>

                    </>
                  ))}</>
            }
          </div> */}
          {/* table body end */}

          {/* table footer   */}

          <div className="d-sm-flex justify-content-between align-items-center mt-5 mb-3 text-center">
            <p className="para mb-sm-0">
              Showing Products <b>{indexOfFirstItem + 1}</b> -
              <b>
                {a + b > tabledata?.data?.pagiantion.dataCount
                  ? tabledata?.data?.pagiantion.dataCount
                  : a + b}{" "}
                &nbsp;
              </b>
              Of <b>{tabledata?.data?.pagiantion.dataCount}</b> Results
            </p>
            <Pagination
              dataOnPage={dataOnPage}
              currentPage={currentPage}
              totalPages={Math.ceil(
                tabledata?.data?.pagiantion.dataCount / itemsPerPage
              )}
              onPageChange={handlePageChange}
              indexOfFirstItem={indexOfFirstItem}
              // currentData={currentData}
              itemsPerPage={itemsPerPage}
              indexOfLastItem={indexOfLastItem}
            />
          </div>

          {/* table footer end  */}
        </div>
      </div>
    </section>
  );
}
