import { useEffect, useState } from "react";
import { app_url } from "../../config";
import toast from "react-hot-toast";
import Loader from "../Loader";
import Slider from "react-slick";
import useMediaQuery from "../../customHooks/useMediaQuery";
import { Link, useNavigate } from "react-router-dom";
import OrderCompletedModal from "./OrderCompletedModal";

export default function EsimsModal({
  topUp,
  setTopUp,
  carrierProviderId,
  regionalCountryId,
  localCountriesId,
  iccid,
  packageType,
  systemOrderId,
  plan_type,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const localData = localStorage.getItem("customerEtijwaal");
  const token = JSON.parse(localData).token;
  const [data, setData] = useState([]);
  const { width } = useMediaQuery();
  const navigate = useNavigate();
  // const [iccid, setIccid] = useState("")
  console.log(iccid);
  const getPackagesById = async () => {
    setIsLoading(true);

    try {
      if (packageType === "local") {
        const data = await fetch(
          `${app_url}/api/package/getPackagesForTopUpByLocalCountriesIdAndCpId?localCountriesId=${localCountriesId}&carrierProviderId=${carrierProviderId}&plan_type=${plan_type}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const response = await data.json();
        console.log(response);
        handleResponse(response, data);
      } else if (packageType === "regional") {
        const data = await fetch(
          `${app_url}/api/package/getPackagesForTopUpByRegionalCountriesIdAndCpId?regionalCountriesId=${regionalCountryId}&carrierProviderId=${carrierProviderId}&plan_type=${plan_type}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const response = await data.json();
        console.log(response);
        handleResponse(response, data);
      } else {
        const data = await fetch(
          `${app_url}/api/package/getPackagesForGlobalTopUpByCpId?carrierProviderId=${carrierProviderId}&plan_type=${plan_type}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const response = await data.json();
        console.log(response);
        handleResponse(response, data);
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  const handleResponse = (response, apiResponse) => {
    if (response.success) {
      setData(response.topUp);
      setIsLoading(false);
    } else if (apiResponse.status === 401) {
      navigate("/login");
    } else {
      throw new Error(response.error);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  useEffect(() => {
    if (plan_type && carrierProviderId && topUp) {
      getPackagesById();
    }
  }, [plan_type, carrierProviderId, topUp]);

  return (
    <>
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-keyboard="false"
        tabindex="-1"
        data-bs-backdrop="static"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class={`modal-dialog modal-dialog-centered modal-lg`}>
          <div class="modal-content">
            <div class="border-0 modal-header">
              <h2 className="text-center mx-auto">TOP UP Packages</h2>
              {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div class="modal-body">
              <div class="row justify-content-center align-items-center gap-0">
                {isLoading ? (
                  <div className="parent-loader">
                    <Loader />
                  </div>
                ) : width >= 320 && width <= 500 ? (
                  <>
                    <p className="text-center">
                      Kindly make sure that the previous ordered eSim you have
                      selected is currently activated in your phone. <br />
                      Your eSim iccid is <b>{iccid}</b>.
                    </p>

                    <Slider {...settings}>
                      {data.map((topups) => (
                        <div
                          key={topups.id}
                          class={`col-10 mt-5 mt-sm-0 col-sm-6 col-md-6 col-lg-4`}
                        >
                          <div
                            key={topups.id}
                            style={{ background: topups.packageCardColor }}
                            class="card mb-5"
                          >
                            <div class="card-body">
                              <div className="d-flex mb-3 justify-content-between ">
                                <div>
                                  <h5 class="card-title text-white d-inline">
                                    {topups.title}
                                  </h5>
                                  {/* <p class="card-text text-white">United Kingdom</p> */}
                                </div>
                                <div className="-mt-45">
                                  <img
                                    src={app_url + topups.cardImage}
                                    width={85}
                                    className="img-fluid"
                                    height={"auto"}
                                    alt="img"
                                  />
                                </div>
                              </div>

                              <div className="border-bottom pb-2 mb-3 text-white">
                                <span className="text-white">
                                  {" "}
                                  <i class="bi bi-globe2 me-2 text-white"></i>{" "}
                                  Coverage
                                </span>{" "}
                                <span className="float-right text-white">
                                  {topups.shortInfo}
                                </span>
                              </div>
                              <div className="border-bottom pb-2 mb-3">
                                <span className="text-white">
                                  <i class="bi bi-clipboard-data me-2 text-white"></i>{" "}
                                  Data
                                </span>{" "}
                                <span className="text-white float-right">
                                  {topups.includeData}
                                </span>
                              </div>
                              <div className="border-bottom pb-2 mb-3">
                                <span className="text-white">
                                  <i class="bi bi-calendar-fill me-2 text-white"></i>{" "}
                                  Validity
                                </span>{" "}
                                <span className="float-right text-white">
                                  {topups.day}{" "}
                                  {topups.day === 1 ? "Day" : "Days"}
                                </span>
                              </div>
                              <button
                                type="button"
                                data-bs-dismiss="modal"
                                onClick={() => {}}
                                className="btn border rounded-0 w-100 text-white"
                              >
                                <Link
                                  className="text-white"
                                  to={`/topupOrders/${topups.id}/systemOrderId?=${systemOrderId}`}
                                >
                                  US $ {topups.price} - BUY TOPUP NOW
                                </Link>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </>
                ) : (
                  <>
                    <p className="text-center mb-5">
                      Kindly make sure that the previous ordered eSim you have
                      selected is currently activated in your phone. <br />
                      Your eSim iccid is <b>{iccid}</b>.
                    </p>

                    {data.map((topups) => (
                      <div
                        key={topups.id}
                        class={`col-10 col-sm-6 col-md-6 col-lg-4`}
                      >
                        {/* Add new Content for Line... */}

                        {/* End Content for Line.... */}
                        <div
                          key={topups.id}
                          style={{ background: topups.packageCardColor }}
                          class="card mb-5"
                        >
                          <div class="card-body">
                            <div className="d-flex mb-3 justify-content-between ">
                              <div>
                                <h5 class="card-title text-white d-inline">
                                  {topups.title}
                                </h5>
                                {/* <p class="card-text text-white">United Kingdom</p> */}
                              </div>
                              <div className="-mt-45">
                                <img
                                  src={app_url + topups.cardImage}
                                  width={85}
                                  className="img-fluid"
                                  height={"auto"}
                                  alt="img"
                                />
                              </div>
                            </div>

                            <div className="border-bottom pb-2 mb-3 text-white">
                              <span className="text-white">
                                {" "}
                                <i class="bi bi-globe2 me-2 text-white"></i>{" "}
                                Coverage
                              </span>{" "}
                              <span className="float-right text-white">
                                {topups.shortInfo}
                              </span>
                            </div>
                            <div className="border-bottom pb-2 mb-3">
                              <span className="text-white">
                                <i class="bi bi-clipboard-data me-2 text-white"></i>{" "}
                                Data
                              </span>{" "}
                              <span className="text-white float-right">
                                {topups.includeData}
                              </span>
                            </div>
                            <div className="border-bottom pb-2 mb-3">
                              <span className="text-white">
                                <i class="bi bi-calendar-fill me-2 text-white"></i>{" "}
                                Validity
                              </span>{" "}
                              <span className="float-right text-white">
                                {topups.day} {topups.day === 1 ? "Day" : "Days"}
                              </span>
                            </div>
                            <button
                              type="button"
                              data-bs-dismiss="modal"
                              onClick={() => {}}
                              className="btn border rounded-0 w-100 text-white"
                            >
                              <Link
                                className="text-white"
                                to={`/topupOrders/${topups.id}/systemOrderId?=${systemOrderId}`}
                              >
                                US $ {topups.price} - BUY TOPUP NOW
                              </Link>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>

            <div class="modal-footer border-0">
              <button
                type="button"
                class="btn btn-secondary"
                onClick={setTopUp}
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {/* <button type="button" class="btn btn-primary">Save changes</button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
